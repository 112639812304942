<template>
    <div class="experimentalSpace">
        <div class="PersonalInfoBack">
            <div class="PersonalInfo" v-if="UserInfoAll">
                <div class="lab-header">
                    <img :src="UserInfoAll.student_card_path" alt="">
                </div>
                <div class="userName">
                    {{ UserInfoAll.nickname }}
                </div>
            </div>
        </div>
        <div class="ManagementCenter-centent-right">
            <!-- <div class="ClassTab">
                <div class="ClassTabItem chack">实验项目</div>
                <div class="ClassTabItem">实验竞赛</div>
            </div> -->
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="实验项目" name="ExperimentalProjects" style="padding:20px">
                    <div class="filter-box">
                        <div class="inputSerch">
                            <el-input placeholder="请输入内容" clearable v-model="parameter.keywords">
                                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                            </el-input>
                        </div>

                        <div class="confirmButton">
                            <el-button type="primary" @click="GetSpaceList">搜索</el-button>
                        </div>
                        <div class="createBtn" @click="dialogVisible = true">创建项目</div>
                    </div>
                    <el-table :data="SpaceListArr" style="width: 100%">
                        <el-table-column prop="challenge_title" label="比赛名称">
                        </el-table-column>
                        <el-table-column prop="project_name" label="实验项目名称">
                        </el-table-column>
                        <!-- <el-table-column prop="rank" label="排名">
                        </el-table-column> -->
                        <el-table-column prop="rank_score" label="排名分数">
                        </el-table-column>
                        <el-table-column label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button @click="EnteringExperimentalSpace(scope.row)" type="text"
                                    size="small">进入实验空间</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button @click="Updata(scope.row)" type="text"
                                    size="small">修改空间名称</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="Placeholder"></div>
                    <pagination :total="SpaceListArrTotle" :page.sync="parameter.pageNum" :limit.sync="parameter.pageSize"
                        @pagination="GetSpaceList" />
                    <div class="Placeholder"></div>
                </el-tab-pane>
                <el-tab-pane label="实验竞赛" name="ExperimentalCompetition">
                    <div class="CompetitionList">
                        <div class="container-comp">
                            <div class="container-comp-center comp-m">
                                <div class="spin-nested-loading">
                                    <div class="spin-container">
                                        <div class="game-list">
                                            <ul class="container-comp-list">
                                                <li class="list-row" v-for="item in MatchListData" :key="item.challenge_id">
                                                    <div class="list-row-top" @click="ProjectJump(item)">
                                                        {{ item.challenge_title }}
                                                        <div class="tag tag-has-color">算法大赛</div>
                                                    </div>
                                                    <div class="list-row-footer">
                                                        <div class="list-row-abstract">
                                                            <!-- <div class="list-row-abstract-top" v-html="item.challenge_detail">
                        
                      </div> -->
                                                        </div>
                                                        <div class="row-rewards">
                                                            <div class="row-rewards-top">报名开始时间</div>
                                                            <div class="row-rewards-footer">
                                                                {{ item.registration_time }}
                                                            </div>
                                                        </div>
                                                        <div class="row-rewards">
                                                            <div class="row-rewards-top">比赛开始时间</div>
                                                            <div class="row-rewards-footer">
                                                                {{ item.start_time }}
                                                            </div>
                                                        </div>
                                                        <div class="row-rewards">
                                                            <div class="row-rewards-top">比赛结束时间</div>
                                                            <div class="row-rewards-footer">{{ item.en_time }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="active-text">
                                                        <span :style="item.challenge_status == 0
                                                            ? 'color: #fa8c16;'
                                                            : 'color:#ccc;'
                                                            ">
                                                            <!-- {{ item.challenge_status == 0 ? "进行中" : item.challenge_status == 1 ? '已结束':'已暂停' }} -->
                                                            {{ CalculateGameStatus(item.registration_time, item.start_time,
                                                                item.en_time, item.challenge_status) }}
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                            <pagination :total="Total" :page.sync="Params.pageNum"
                                                :limit.sync="Params.pageSize" @pagination="getdata" />
                                            <div style="width: 100%;height: 40px;">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

        <el-dialog title="新建实验项目" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <el-form :model="CreateSpaceParams" :rules="rules" ref="ruleForm" class="demo-form-inline">
                <el-form-item label="实验项目名称" prop="project_name">
                    <el-input v-model="CreateSpaceParams.project_name" placeholder="请输入实验项目名称"></el-input>
                </el-form-item>
                <el-form-item label="实验比赛" prop="match_id">
                    <el-select style="width: 100%" clearable v-model="CreateSpaceParams.match_id" placeholder="请选择实验比赛">
                        <el-option v-for="item in notCreateSpace" :key="item.match_id" :label="item.challenge_title"
                            :value="item.match_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">新增</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog title="修改实验项目名称" :visible.sync="UpdatadialogVisible" width="30%" :before-close="handleClose">
            <el-form :model="updataParams" class="demo-form-inline">
                <el-form-item label="实验项目名称" prop="project_name">
                    <el-input v-model="updataParams.project_name" placeholder="请输入实验项目名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="UpdateInformation">修改</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>
<script>
import {
    getInfo,
    MatchList,
    space_list,
    enter_space,
    create_space,
    have_space,
    not_create_space,
    update_space
} from "../../api/ThisInfo.js";
export default {
    name: 'experimentalSpace',
    data() {
        return {
            UserInfoAll: undefined,
            MathDataList: undefined,
            SpaceListArr: undefined,
            SpaceListArrTotle: 0,
            parameter: {
                "keywords": "",
                "pageNum": 1,
                "pageSize": 10
            },
            activeName: 'ExperimentalProjects',
            MatchListData: [],
            Params: {
                keywords: "",
                pageNum: 1,
                pageSize: 10,
            },
            Total: 0,
            dialogVisible: false,
            CreateSpaceParams: {
                project_name: '',
                match_id: '',
            },
            rules: {
                project_name: [
                    { required: true, message: '请输入实验项目名称', trigger: 'blur' },
                ],
                match_id: [
                    { required: true, message: '请选择实验比赛', trigger: 'change' }
                ]
            },
            updataParams:{
                "id": "", 
                "project_name": "" 
            },
            UpdatadialogVisible:false,
            notCreateSpace:[]
        }
    },
    created() {
        this.getInfodata();
        // this.GetMatchSearch()
        this.GetSpaceList()
        this.getdata();
        this.GetnotCreateSpace()
    },
    methods: {
        Updata(data){
            this.updataParams= {
                "id": data.id, 
                "project_name": data.project_name
            }
            this.UpdatadialogVisible = true
        },
        GetnotCreateSpace() {
            not_create_space({
                "keywords": "",
                "pageNum": "1",
                "pageSize": "10"
            }).then(res => {
                this.notCreateSpace = res.data.list
                console.log(res, '查看可创建的空间')
            })
        },
        // 进入实验空间
        EnteringExperimentalSpace(data) {
            this.$store.commit("app/CHANGE_LOADING", true);
            console.log(data.match_id)
            enter_space({
                match_id: data.match_id
            }).then(res => {
                if(res.code == 200){
                    console.log(res)
                    this.$store.commit("app/CHANGE_LOADING", false);
                    window.open(res.url);
                }else{
                    this.$message.error('进入空间失败');
                    this.$store.commit("app/CHANGE_LOADING", false);
                }   
                
            }).catch(err=>{
                this.$message.error('进入空间失败');
                this.$store.commit("app/CHANGE_LOADING", false);
            })
        },
        UpdateInformation(){
            if(this.updataParams.project_name.length == 0)return
            console.log(this.updataParams,'this.updataParams')
            update_space(this.updataParams).then(res => {
                this.handleClose()
                this.GetSpaceList()
            })
        },
        onSubmit() {
            have_space({
                match_id: this.CreateSpaceParams.match_id,
            }).then(res => {
                if (res.is_create) {
                    this.$notify({
                        title: '该比赛已经被创建空间',
                        message: `空间名称为：${res.project_name}`,
                        type: 'warning'
                    });
                } else {
                    create_space(this.CreateSpaceParams).then(res => {
                        this.$message({
                            message: '新建空间成功',
                            type: 'success'
                        });
                        this.handleClose()
                        this.GetSpaceList()
                    })
                }
            })

        },
        handleClose() {
            this.dialogVisible = false
            this.UpdatadialogVisible = false
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        // 获取用户信息
        getInfodata() {
            getInfo().then((res) => {
                this.UserInfoAll = JSON.parse(JSON.stringify(res.data));
                this.$forceUpdate();
            });
        },
        // 获取比赛列表
        GetMatchSearch() {
            MatchList({
                pageNum: "1",
                pageSize: "100",
            }).then((res) => {
                this.MathDataList = res.data.list;
            });
        },
        GetSpaceList() {
            space_list(this.parameter).then(res => {
                this.SpaceListArr = res.data.list
                this.SpaceListArrTotle = res.data.total
                console.log(res, '空间')
            })
        },
        getdata() {
            MatchList(this.Params).then((res) => {
                this.MatchListData = res.data.list;
                this.Total = Number(res.data.total);
            });
        },
        // 指定项目跳转
        ProjectJump(item) {
            // if (item.challenge_status == 1) {
            //   this.$message.error("当前比赛已结算，请下次开放时参加！");
            //   return;
            // }
            this.$router.push({
                path: "/SingleItem",
                query: {
                    id: item.challenge_id,
                    // matchstate: item.challenge_status,
                },
            });
        },
        // 计算比赛状态
        CalculateGameStatus(
            registration_time,
            start_time,
            en_time,
            challenge_status
        ) {
            if (challenge_status == "-1") {
                return "已暂停";
            } else if (challenge_status == "1") {
                return "已结束";
            } else if (challenge_status == "0") {
                if (
                    new Date(this.getNowDate()) > new Date(registration_time) &&
                    new Date(this.getNowDate()) < new Date(start_time)
                ) {
                    return "报名中";
                } else if (
                    new Date(this.getNowDate()) > new Date(start_time) &&
                    new Date(this.getNowDate()) < new Date(en_time)
                ) {
                    return "进行中";
                } else {
                    return "进行中";
                }
            }
        },
        // 格式化日对象
        getNowDate() {
            var date = new Date();
            var sign2 = ":";
            var year = date.getFullYear(); // 年
            var month = date.getMonth() + 1; // 月
            var day = date.getDate(); // 日
            var hour = date.getHours(); // 时
            var minutes = date.getMinutes(); // 分
            var seconds = date.getSeconds(); //秒
            // 给一位数的数据前面加 “0”
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (day >= 0 && day <= 9) {
                day = "0" + day;
            }
            if (hour >= 0 && hour <= 9) {
                hour = "0" + hour;
            }
            if (minutes >= 0 && minutes <= 9) {
                minutes = "0" + minutes;
            }
            if (seconds >= 0 && seconds <= 9) {
                seconds = "0" + seconds;
            }
            return (
                year +
                "-" +
                month +
                "-" +
                day +
                " " +
                hour +
                sign2 +
                minutes +
                sign2 +
                seconds
            );
        },
    }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";

@media (min-width: 1400px) {

    .page-m,
    .comp-m,
    .title-m,
    .about-m,
    .bussiness-m,
    .footer-m,
    .box-m {
        width: 1700px;
    }
}

@media (min-width: 992px) {

    .page-m,
    .comp-m,
    .title-m,
    .about-m,
    .bussiness-m,
    .footer-m,
    .box-m {
        width: 85.7%;
        width: 100%;
    }
}

.experimentalSpace {
    .PersonalInfoBack {
        width: 100%;
        height: 160px;
        background-color: #fff;

        .PersonalInfo {
            width: 100%;
            height: 160px;
            background: url('../../assets//banner/user-center.24704fd9.png') no-repeat;
            background-size: cover;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            .lab-header {
                display: block;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                overflow: hidden;
                margin-bottom: 16px;
                border: 1px solid #b5e3ff;
                margin: 0 auto;

                img {
                    width: 80px;
                    height: 80px;
                }
            }

            .userName {
                width: 200px;
                height: 40px;
                line-height: 40px;
                font-size: 24px;
                font-weight: 700;
                color: #455366;
                line-height: 32px;
                text-align: center;
            }
        }
    }

    .ManagementCenter-centent-right {
        width: 82.6%;
        min-height: 726px;
        // float: right;
        background: #fff;
        padding-bottom: 100px;
        font-size: 14px;
        // padding: 0px 18px 30px;
        margin: 0 auto;

        .Placeholder {
            width: 100%;
            height: 20px;
        }

        .filter-box {
            display: flex;
            width: 100%;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-bottom: 16px;
            position: relative;
            position: relative;

            .inputSerch {
                width: 300px;
                height: 40px;
            }

            .confirmButton {
                width: 60px;
                height: 40px;
                margin-left: 20px;
            }

            .createBtn {
                position: absolute;
                right: 0;
                top: 0;
                width: 80px;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                color: #fff;
                cursor: pointer;
                background-color: #1e7cff;
                border-radius: 2px;
                text-align: center;
            }
        }

        .ClassTab {
            width: 100%;
            height: 58px;
            display: flex;

            .ClassTabItem {
                width: 200px;
                height: 48px;
                line-height: 48px;
                color: #ccc;
                font-weight: 600;
                font-size: 24px;
                cursor: pointer;
                // border-right: 0 solid rgb(43, 103, 238);
                transition: all .1s;
                background-color: #b5e3ff;

                text-align: center;
            }

            .chack {
                color: rgb(43, 103, 238);
                border-bottom: 5px solid rgb(43, 103, 238);
            }
        }
    }

}
</style>